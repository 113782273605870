import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am <span className="purple">Helina Tefera </span>
            from <span className="purple"> Adama ,Ethiopia</span>
            <br /> About
I have a strong background in building scalable and practical web applications using Python and its popular web framework Django.
            <br />
            Proficient in various technologies including database management, debugging, and testing tools. I am passionate about continuously improving my knowledge and skills, and always eager to learn new technologies.
            <br />
            <br />
             </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
