import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import dnb from "../../Assets/Projects/dnb.png";
import rc from "../../Assets/Projects/rc.png";
import btd from "../../Assets/Projects/btd.png";
import tm from "../../Assets/Projects/tm.png";
import sm from "../../Assets/Projects/sm.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Simple<strong className="purple"> Projects </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={btd}
              isBlog={false}
              title="BTDC"
              description="I've created a Django application for brain tumor detection and classification using TensorFlow and Keras. This app likely serves as a valuable tool for diagnosing and categorizing brain tumors. My project demonstrates the power of technology in healthcare and highlights my expertise in machine learning and web development through Django."
              ghLink="https://github.com/helinatefera/Brain-Tumor-Detection"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sm}
              isBlog={false}
              title="Store Management System"
              description="I've built a Store Management system for our university's backend using Django, and it's powered by PostgreSQL as the database. This system covers everything we need for managing the university store. I chose Django and PostgreSQL to ensure a strong and scalable solution that can handle the university's store data effectively."
              ghLink="
              3"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rc}
              isBlog={false}
              title="React Camera"
              description="I've created a simple React camera application that allows you to capture images directly on your device. This user-friendly tool simplifies the process of taking pictures and storing them locally, making it convenient for various applications such as documentation, photography, or any project requiring image capture."
              ghLink="https://github.com/helinatefera/react-camera-app"            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dnb}
              isBlog={false}
              title="Django Notebook"
              description="I've developed a Django notebook application that facilitates the creation, editing, and deletion of notes. This application is designed to help users easily jot down and manage their notes, enhancing organization and productivity. With the ability to add, edit, and delete notes, it provides a convenient way to keep track of important information and tasks."
              ghLink="https://github.com/helinatefera/django-notebook"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tm}
              isBlog={false}
              title="Task Management System"
              description="I've built a straightforward yet effective task management system using React for the frontend and Django with SQLite for the backend. This system streamlines the process of creating, tracking, and managing tasks, offering a user-friendly interface through React while ensuring data storage and retrieval reliability via Django and SQLite. It's a practical solution for organizing and prioritizing tasks, showcasing my skills in web development and backend database management."
              ghLink="https://github.com/helinatefera/Django-React-Todo"
              />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
